// AUTH
export const LOGIN_ACTION = 'LOGIN_ACTION';
export const REGISTER_ACTION = 'REGISTER_ACTION';
export const BEFORE_LOGIN = 'BEFORE_LOGIN';
export const RESET_REQUIRED = 'RESET_REQUIRED';
export const BLOCKED_USER = 'BLOCKED_USER';

// PASSWORD
export const BEFORE_PASSWORD = 'BEFORE_FORGOT';
export const SEND_EMAIL = 'SEND_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// PROFILE
export const BEFORE_PROFILE = 'BEFORE_PROFILE';
export const USER_UPDATED_SUCCESSFULLY = 'USER_UPDATED_SUCCESSFULLY';
export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUBMIT = 'UPDATE_EMAIL_SUBMIT';
export const CHECK_TRUST_CERT = 'CHECK_TRUST_CERT';
export const REQUEST_TRUST_CERT_SUBMIT = 'REQUEST_TRUST_CERT_SUBMIT';

// PHONE
export const BEFORE_PHONE = 'BEFORE_PROFILE';
export const NUMBER_TO_VERIFY = 'NUMBER_TO_VERIFY';
export const VERIFY_BY_PIN = 'VERIFY_BY_PIN';
export const PIN_SENT_FOR_NUMBER = 'PIN_SENT_FOR_NUMBER';
export const DELETE_NUMBER = 'DELETE_NUMBER';
export const UPDATE_STATUS_NUMBER = 'UPDATE_STATUS_NUMBER';
export const USER_PHONE_NUMBER = 'USER_PHONE_NUMBER';
export const EMPTY_PHONES_LIST = 'EMPTY_PHONES_LIST';
export const USER_MAIN_PHONE_NUMBER = 'USER_MAIN_PHONE_NUMBER';

// ERRORS
export const GET_ERRORS = 'GET_ERRORS';
export const EMPTY_ERRORS = 'EMPTY_ERRORS';

// SHARED
export const BEFORE_SHARED = 'BEFORE_SHARED';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ACTIVE_USER_DATA = 'SET_ACTIVE_USER_DATA';
export const SET_COUNTRY_LANGUAGE_DATA = 'SET_COUNTRY_LANGUAGE_DATA';
export const ADD_COUNTRY_REQUEST = 'ADD_COUNTRY_REQUEST';
export const SUBMIT_CONTACT_SUPPORT_REQUEST = 'SUBMIT_CONTACT_SUPPORT_REQUEST';
export const COUNTRY_CODE_DATA = 'COUNTRY_CODE_DATA';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SITE_DATA = 'SITE_DATA';
export const SETTINGS_DATA = 'SETTINGS_DATA';

// FOOTER CMS PAGES
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_CMS_ACTION = 'GET_CMS_ACTION';
export const GET_CUSTOMER_SERVICE_LIST = 'GET_CUSTOMER_SERVICE_LIST'
export const GET_USER_INFO_LIST = 'GET_USER_INFO_LIST'
export const GET_USER_DETAIL = 'GET_USER_DETAIL'

// LANDING PAGE
export const BEFORE_LANDING = 'BEFORE_LANDING';
export const LANDING_PAGE_DATA = 'LANDING_PAGE_DATA';

//NEWS LETTER SUBSCRIPTION
export const CREATE_NEWS_LETTER = 'CREATE_NEWS_LETTER';

// CMS PAGE
export const BEFORE_PAGE = 'BEFORE_PAGE';
export const GET_PAGE_ACTION = 'GET_PAGE_ACTION';

// ADS
export const BEFORE_ADS = 'BEFORE_ADS';
export const GET_AD_FAMILIES = 'GET_AD_FAMILIES';
export const CREATE_AD = 'CREATE_AD';
export const GET_AD_LIST = 'GET_AD_LIST';
export const GET_AD = 'GET_AD';
export const EDIT_AD = 'EDIT_AD';
export const PREVIEW_AD = 'PREVIEW_AD';
export const DELETE_AD = 'DELETE_AD';
export const GET_AD_ONLY = 'GET_AD_ONLY';
export const AD_CERTIFICATION_REQUEST = 'AD_CERTIFICATION_REQUEST';
export const AD_PLAN_REQUEST = 'AD_PLAN_REQUEST';
export const EMPTY_AD_LIST = 'EMPTY_AD_LIST';
export const GET_FAV_AD = 'GET_FAV_AD';
export const UNFAV_AD = 'UNFAV_AD';
export const GET_CERTIFICATION_AD_LIST = 'GET_CERTIFICATION_AD_LIST';
export const EMPTY_CERTIFICATION_AD_LIST = 'EMPTY_CERTIFICATION_AD_LIST';
export const GET_RENEW_AD_LIST = 'GET_RENEW_AD_LIST';
export const EMPTY_RENEW_AD_LIST = 'EMPTY_RENEW_AD_LIST';
export const GET_AD_PLANNER_LIST = 'GET_AD_PLANNER_LIST';
export const EMPTY_AD_PLANNER_LIST = 'EMPTY_AD_PLANNER_LIST';
export const GET_PUBLICATION_ADS_LIST = 'GET_PUBLICATION_ADS_LIST';
export const EMPTY_PUBLICATION_ADS_LIST = 'EMPTY_PUBLICATION_ADS_LIST';
export const GET_RELOCATE_ADS_LIST = 'GET_RELOCATE_ADS_LIST';
export const EMPTY_RELOCATE_ADS_LIST = 'EMPTY_RELOCATE_ADS_LIST';
export const EMPTY_FAV_AD = 'EMPTY_FAV_AD';
export const LIST_ALL_FAMILIES = 'LIST_ALL_FAMILIES';
export const EMPTY_ALL_FAMILIES = 'EMPTY_ALL_FAMILIES';
export const GET_AD_PARTICULAR = 'GET_AD_PARTICULAR';
export const EMAIL_SENT_TO_PUBLISHER = 'EMAIL_SENT_TO_PUBLISHER';
export const CREATE_FAVOURITE_AD = 'CREATE_FAVOURITE_AD';
export const CREATE_RATE_AD = 'CREATE_RATE_AD';
export const CREATE_REPORT_AD = 'CREATE_REPORT_AD';
export const PAUSE_AD = 'PAUSE_AD';
export const GET_PROFILE_TEXT = 'GET_PROFILE_TEXT';
export const FILTERED_ADS_LIST = 'FILTERED_ADS_LIST';
export const AD_STATS = 'AD_STATS'
export const AD_BEFORE_DATA_HOOK = 'AD_BEFORE_DATA_HOOK'
export const GET_AD_SETTINGS = 'GET_AD_SETTINGS'
export const RELOCATE_AD = 'RELOCATE_AD'
export const EXTEND_AD = 'EXTEND_AD'
export const RENEW_AD = 'RENEW_AD'
export const RENEW_FREE_AD = 'RENEW_FREE_AD'
export const EMPTY_FILTERED_ADS_LIST = 'EMPTY_FILTERED_ADS_LIST' 
export const PAYNOW_AD = 'PAYNOW_AD'
export const FETCH_RADIUS_RANGE = 'FETCH_RADIUS_RANGE'

// DASHBOARD
export const BEFORE_DASHBOARD = 'BEFORE_DASHBOARD';
export const CHECK_USER_DRAFT = 'CHECK_USER_DRAFT';
export const GET_NUMERIC_CODE  = 'GET_NUMERIC_CODE';

// FAQ
export const BEFORE_FAQ = 'BEFORE_FAQ'
export const GET_FAQ_LIST = 'GET_FAQ_LIST'

// BILLING
export const BEFORE_BILLING = 'BEFORE_BILLING'
export const GET_BILLING = 'GET_BILLING'
export const GET_BILLING_LIST = 'GET_BILLING_LIST'
export const EMPTY_BILLING_LIST = 'EMPTY_BILLING_LIST'

// TICKETS
export const BEFORE_TICKETS = 'BEFORE_TICKETS'
export const FREE_TICKETS = 'FREE_TICKETS'
export const BUY_TICKETS = 'BUY_TICKETS'

// BANNER
export const BEFORE_BANNER = 'BEFORE_BANNER'
export const GET_BANNER = 'GET_BANNER'
export const GET_BANNER_LIST = 'GET_BANNER_LIST' // PROMOTIONAL BANNER LIST
export const GET_NORMAL_BANNER_LIST = 'GET_NORMAL_BANNER_LIST'
export const HIDE_NORMAL_BANNERS = 'HIDE_NORMAL_BANNERS'
export const BANNER_SPENT_TIME = 'BANNER_SPENT_TIME'
export const UPDATE_BANNER_STATS = 'UPDATE_BANNER_STATS'
export const UPDATE_PROMO_BANNER_STATS = 'UPDATE_PROMO_BANNER_STATS'

// PROMO
export const BEFORE_PROMO = 'BEFORE_PROMO'
export const GET_PROMO_LIST = 'GET_PROMO_LIST'
export const EMPTY_PROMO_LIST = 'EMPTY_PROMO_LIST'

// CHAT MANAGE USERS
export const BEFORE_CHAT = 'BEFORE_CHAT'
export const GET_USER = 'GET_USER'
export const GET_PUBLISHER_ID = 'GET_PUBLISHER_ID'
export const GET_EXTERNAL_ID = 'GET_EXTERNAL_ID'

// LANGUAGE
export const BEFORE_LANGUAGE = 'BEFORE_LANGUAGE'
export const GET_LANGUAGES = 'GET_LANGUAGES'

// CONTENT 
export const BEFORE_CONTENT = 'BEFORE_CONTENT'
export const BEFORE_HEADER_CONTENT = 'BEFORE_HEADER_CONTENT'
export const BEFORE_FOOTER_CONTENT = 'BEFORE_FOOTER_CONTENT'
export const BEFORE_AD_COMPONENT_CONTENT = 'BEFORE_AD_COMPONENT_CONTENT'
export const BEFORE_AD_CONTENT = 'BEFORE_AD_CONTENT'
export const BEFORE_BANNER_CONTENT = 'BEFORE_BANNER_CONTENT'
export const GET_CONTENT = 'GET_CONTENT'
export const GET_BANNER_CONTENT = 'GET_BANNER_CONTENT'
export const GET_HEADER_CONTENT = 'GET_HEADER_CONTENT'
export const GET_POPULAR_CATEGORIES_CONTENT = 'GET_POPULAR_CATEGORIES_CONTENT'
export const GET_FEATURE_ADS_CONTENT = 'GET_FEATURE_ADS_CONTENT'
export const GET_LAST_ADS_CONTENT = 'GET_LAST_ADS_CONTENT'
export const GET_FOOTER_CONTENT = 'GET_FOOTER_CONTENT'
export const GET_PROFILE_CONTENT = 'GET_PROFILE_CONTENT'
export const GET_AD_CONTENT = 'GET_AD_CONTENT'
export const GET_PASSWORD_MANAGEMENT_CONTENT = 'GET_PASSWORD_MANAGEMENT_CONTENT'
export const GET_AD_COMPONENT_CONTENT = 'GET_AD_COMPONENT_CONTENT'
export const BEFORE_PASSWORD_MANAGEMENT = 'BEFORE_PASSWORD_MANAGEMENT'
export const BEFORE_TRUST_CERTIFICATION = 'BEFORE_TRUST_CERTIFICATION'
export const GET_TRUST_CERTIFICATION_CONTENT = 'GET_TRUST_CERTIFICATION_CONTENT'
export const GET_WELCOME_PACK_CONTENT = 'GET_WELCOME_PACK_CONTENT'
export const BEFORE_WELCOME_PACK_CONTENT = 'BEFORE_WELCOME_PACK_CONTENT'
export const GET_SIGN_IN_CONTENT = 'GET_SIGN_IN_CONTENT'
export const GET_FAQS_CONTENT = 'GET_FAQS_CONTENT'
export const GET_CONTROL_PANEL_CONTENT = 'GET_CONTROL_PANEL_CONTENT'
export const GET_LANDING_PAGE_CONTENT = 'GET_LANDING_PAGE_CONTENT'
export const GET_SETTINGS_SIDEBAR_CONTENT = 'GET_SETTINGS_SIDEBAR_CONTENT'
export const GET_FAV_ADS_CONTENT = 'GET_FAV_ADS_CONTENT'
export const GET_PHONE_NO_CONTENT = 'GET_PHONE_NO_CONTENT'
export const GET_POST_AD_CONTENT = 'GET_POST_AD_CONTENT'
export const GET_PUBLICATION_ADS_CONTENT = 'GET_PUBLICATION_ADS_CONTENT'
export const GET_AD_PLANNER_CONTENT = 'GET_AD_PLANNER_CONTENT'
export const GET_BUY_TICKETS_CONTENT = 'GET_BUY_TICKETS_CONTENT'
export const GET_BILLING_CONTENT = 'GET_BILLING_CONTENT'
export const GET_RENEW_ADS_CONTENT = 'GET_RENEW_ADS_CONTENT'
export const GET_RELOCATE_ADS_CONTENT = 'GET_RELOCATE_ADS_CONTENT'
export const GET_CERTIFICATE_ADS_CONTENT = 'GET_CERTIFICATE_ADS_CONTENT'
export const GET_CERTIFICATION_ADS_CONTENT = 'GET_CERTIFICATION_ADS_CONTENT'
export const GET_PROMOS_CONTENT = 'GET_PROMOS_CONTENT'
export const GET_CONTACT_SUPPORT_CONTENT = 'GET_CONTACT_SUPPORT_CONTENT'
export const GET_AD_DETAIL_CONTENT = 'GET_AD_DETAIL_CONTENT'
export const GET_GENERATED_BILL_CONTENT = 'GET_GENERATED_BILL_CONTENT'
export const GET_REUBICATE_ADS_CONTENT = 'GET_REUBICATE_ADS_CONTENT'
export const GET_PUBLICATE_ADS_CONTENT = 'GET_PUBLICATE_ADS_CONTENT'
export const GET_REPLAN_AD_CONTENT = 'GET_REPLAN_AD_CONTENT'
export const GET_ALL_CATEGORIES_CONTENT = 'GET_ALL_CATEGORIES_CONTENT'
export const GET_PREVIEW_AD_CONTENT = 'GET_PREVIEW_AD_CONTENT'
export const GET_FILTERED_ADS_CONTENT = 'GET_FILTERED_ADS_CONTENT'
export const GET_LOI_CONTENT = 'GET_LOI_CONTENT'
export const GET_BONUS_PAYMENT_CONTENT = 'GET_BONUS_PAYMENT_CONTENT'
export const GET_PAYMENT_CONTENT = 'GET_PAYMENT_CONTENT'
export const GET_NOTICE_CONTENT = 'GET_NOTICE_CONTENT'
export const GET_BLOCKED_FEATURES = 'GET_BLOCKED_FEATURES'

// PAYMENT
export const BEFORE_PAYMENT = 'BEFORE_PAYMENT'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'

// LOI
export const BEFORE_LOI = 'BEFORE_LOI'
export const GET_LOI_LIST = 'GET_LOI_LIST'

// BONUS PAYMENTS
export const BEFORE_BONUS_PAYMENTS = 'BEFORE_BONUS_PAYMENTS'
export const GET_BONUS_PAYMENTS = 'GET_BONUS_PAYMENTS'