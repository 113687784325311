import { BEFORE_DASHBOARD, CHECK_USER_DRAFT, GET_NUMERIC_CODE } from '../../redux/types';

const initialState = {
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_USER_DRAFT:
            return {
                ...state,
                checkUserDraftAdsAuth: true,
                checkUserDraftData: action.payload
            }
        case BEFORE_DASHBOARD:
            return {
                ...state,
                checkUserDraftAdsAuth: false,
            }
            case GET_NUMERIC_CODE:
                return {
                    ...state,
                    numericCode: action.payload,
                }
        default:
            return {
                ...state
            }
    }
}